import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class Loans extends React.Component {
	render() {
		const siteTitle = 'Loans'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>Loans</h3>
								<p>
									WSC Group – Finance can provide you with finance for most
									situations, including: home loans, commercial loans,
									investment loans, refinancing and consolidation debts,
									business loans, car, boat and equipment leasing. We also
									provide a service which will focus on a debt consolidation
									strategy.
									<br />
									<br />
									Sourcing the optimum interest rate is another focal point of
									WSC Group –Finance loan service, as the aim is to assist you
									in achieving a supreme financial position.
									<br />
									<br />
									WSC Group – Finance will review current loan scenarios and
									highlight areas that are not working at an optimum level and
									offer advice whether it is fixing or part fixing loans. We are
									also able to source more competitive interest rates for
									clients.
									<br />
									<br />
									We understand the stress associated with sourcing a new loan
									whether it is for a new home, car and insurance or for
									commercial purposes. At WSC Group – Finance, our experts
									provide a comprehensive service that will allow you to easily
									compare loans and chose the right one for you.
									<br />
									<br />
									Our highly trained professional team are able to source a wide
									range of loans from and extensive panel of lenders to ensure
									you receive the best loan.
								</p>
								<p>
									<strong>
										WSC Group – Aust Pty Ltd ATF WSC Group – Aust Unit Trust
									</strong>
									<br />
									<br />
									Uses external local partners at our office location to provide
									Credit Services. For details about our lending partners at
									each location contact{' '}
									<a href="mailto:info@wscgroup.com.au">
										info@wscgroup.com.au{' '}
									</a>
									Our finance partners work with your accountant to ensure that
									your finances are in line with your tax planning strategies.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Loans

export const LoansPageQuery = graphql`
	query LoansPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
